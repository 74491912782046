<template>
    <div class="db-container robots-list">
        <div class="db-modal robots-history">
            <div class="content">
                <div class="wrapper">
                    <h3>機器人交易紀錄</h3>
                    <span v-if="false" class="timestamp">更新時間： 2022/01/03 15:38:41</span>
                    <div v-if="false" class="empty">
                        <img src="@/assets/images/empty.svg" alt="">
                        <h4>此機器人尚未有交易紀錄！</h4>
                    </div>
                    <div v-if="this.recordModalType == 'fr_arb'" class="table-container">
                        <table v-if="this.recordModalData.length">
                            <tr>
                                <th>交易</th>
                                <th>現貨持倉</th>
                                <th>交易當下現貨面值</th>
                                <th>合約持倉</th>
                                <th>交易當下合約面值</th>
                                <th>交易時間</th>
                            </tr>
                            <tr v-for="(ele,ind) in this.recordModalData" :key="ind">
                                <td>{{ ele.position_type == 'open' ? '開倉' : '平倉' }}</td>
                                <td>{{ (ele.spot_size).toFixed(3) }}<span>{{ ele.market.split('/')[0] }}</span></td>
                                <td><span class="first">{{ ele.market.split('/')[1] }}$</span>{{ (ele.spot_size * ele.spot_price).toFixed(2) }}</td>
                                <td>{{ (ele.future_size).toFixed(3) }}<span>{{ ele.market.split('/')[0] }}</span></td>
                                <td><span class="first">{{ ele.market.split('/')[1] }}$</span>{{ (ele.future_size * ele.future_price).toFixed(2) }}</td>
                                <td>{{ ele.time | recordDateFormat }}</td>
                            </tr>
                        </table>
                        <div v-if="!this.recordModalData.length" class="empty">
                            <img src="@/assets/images/empty.svg" alt="">
                            <h4>機器人尚未有交易紀錄！</h4>
                        </div>
                    </div>
                    <div v-if="this.recordModalType == 'lending'" class="table-container">
                        <table v-if="this.recordModalData.length">
                            <tr>
                                <th>借貸狀態</th>
                                <th>借出單位</th>
                                <th>借出年利率</th>
                                <th>預期借貸天數</th>
                                <th>已借出時間</th>
                                <th>借出時間</th>
                                <th>還款時間</th>
                                <th>目前獲利</th>
                            </tr>
                            <tr v-for="(ele,ind) in this.recordModalData" :key="ind">
                                <td>{{ ele.position_type == 'open' ? '借出中' : '已還款' }}</td>
                                <td>{{ (ele.spot_size).toFixed(4) }}<span>{{ ele.market.substring(1) == 'UST' ? 'USDT' : ele.market.substring(1) }}</span></td>
                                <td>{{ ele.spot_price * 360 | rate(2) }}%</td>
                                <td>{{ ele.period }}天</td>
                                <td>{{ (ele.position_type == 'open' ? Math.ceil((new Date().getTime()-new Date(ele.time).getTime()) / 1000 / 60 / 60) : Math.ceil((new Date(ele.payout_time).getTime()-new Date(ele.time).getTime()) / 1000 / 60 / 60)) }}小時</td>
                                <td v-if="ele.time">{{ ele.time | recordDateFormat }}</td>
                                <td v-else>-</td>
                                <td v-if="ele.payout_time">{{ ele.payout_time | recordDateFormat }}</td>
                                <td v-else>-</td>
                                <td>{{ ele.spot_size * ele.spot_price / 24 * (ele.position_type == 'open' ? Math.ceil((new Date().getTime()-new Date(ele.time).getTime()) / 1000 / 60 / 60) : Math.ceil((new Date(ele.payout_time).getTime()-new Date(ele.time).getTime()) / 1000 / 60 / 60)) | rate(4, false) }}<span>{{ ele.market.substring(1) == 'UST' ? 'USDT' : ele.market.substring(1) }}</span></td>
                            </tr>
                        </table>
                        <div v-if="!this.recordModalData.length" class="empty">
                            <img src="@/assets/images/empty.svg" alt="">
                            <h4>機器人尚未有交易紀錄！</h4>
                        </div>
                    </div>
                </div>
                <div class="btn-container">
                    <button v-if="false" class="blue">匯出CSV</button>
                    <button class="light" @click="closeModal()">關閉</button>
                </div>
            </div>
        </div>
        <RobotSettingModal ref="robotSettingModal" :currencyList="robotType == '1' ? currencyList : lendingCurrencyList" />
        <div class="db-content">
            <h2 class="title">我的機器人</h2>
            <!-- <button class="detach add-robot" @click="$refs.robotSettingModal.openModal(-1)">+ 新增機器人</button> -->
            <div v-if="!this.loaded" class="skeleton-loading-container">
                <div class="skeleton-loading title-loading"></div>
                <div class="skeleton-loading content-loading-1"></div>
                <div class="skeleton-loading content-loading-2"></div>
                <div class="skeleton-loading content-loading-3"></div>
            </div>
            <div v-if="!this.filterRobotList.length && this.loaded" class="empty">
                <img src="@/assets/images/robot-empty.svg" alt="">
                <h4>您尚未新增機器人！</h4>
            </div>
            <template v-for="(ele, ind) in filterRobotList">
                <div v-if="ele.type == 'fr_arb'" class="robot" :key="ind" :data-id="ele._id">
                    <div class="infos">
                        <div class="left">
                            <img :src="loadIcon(ele.params.market)" alt="" class="icon">
                            <h3 class="name"><h5>{{ ele.type == 'fr_arb' ? '資金費率套利' : '放貸機器人' }}</h5>{{ ele.name }}</h3>
                            <span :class="`status${ ele.status == 0 ? '' : ' pause' }`">
                                {{ ele.status == 0 ? '運行中' : '已暫停' }}
                                <div v-if="ele.operate_logs && ele.operate_logs != ''" :data-tip="ele.operate_logs" :style="`background-image: url(${ ele.status == 0 ? require('../../assets/images/info.svg') : require('../../assets/images/info-white.svg')});`"></div>
                            </span>
                        </div>
                        <div class="right">
                            <span>期貨/現貨持倉</span>
                            <h3 v-html="(ele.hold_position && ele.records.length) ? (ele.records.slice(-1)[0].position_type != 'close' ? `${(ele.records.slice(-1)[0].spot_size).toFixed(2)}<span>${ele.params.market.replace('-PERP','')}</span>` : `0.00<span>${ele.params.market.replace('-PERP','')}</span>`) : `0.00<span>${ele.params.market.replace('-PERP','')}</span>`"></h3>
                            <div class="controller">
                                <img src="../../assets/images/delete.svg" alt="" @click="deleteRobot(ele._id)" class="delete" title="刪除機器人">
                                <img src="../../assets/images/history.svg" @click="openModal(ele._id)" alt="" class="history" title="交易紀錄">
                                <img :src="ele.status == 0 ? require('../../assets/images/pause.svg') : require('../../assets/images/play.svg')" @click="robotStatus(ele._id, Math.abs(ele.status - 1))" alt="" class="pause" title="暫停機器人">
                                <img src="../../assets/images/edit.svg" @click="robotType=1;$refs.robotSettingModal.openModal(1, 1, robotList.filter(e => e._id == ele._id)[0]);" alt="" class="edit" title="編輯機器人">
                            </div>
                        </div>
                    </div>
                    <div class="status">
                        <div class="chart">
                            <span>{{ ele.params.market.replace('-PERP','') }}即期費率走勢 (%)</span>
                            <RobotsListsChart :height="100" :records="ele.marketHistory" :key="ele.targetLoaded" :last="ele.marketHistoryLast" :styles="myStyles"/>
                        </div>
                        <div class="numbers">
                            <div class="number mobile">
                                <h5 class="title">期貨/現貨持倉</h5>
                                <p v-html="(ele.hold_position && ele.records.length) ? (ele.records.slice(-1)[0].position_type != 'close' ? `${(ele.records.slice(-1)[0].spot_size).toFixed(2)}<span>${ele.params.market.replace('-PERP','')}</span>` : `0.00<span>${ele.params.market.replace('-PERP','')}</span>`) : `0.00<span>${ele.params.market.replace('-PERP','')}</span>`"></p>
                            </div>
                            <div class="number">
                                <h5 class="title">標的</h5>
                                <p>{{ ele.params.market }}</p>
                            </div>
                            <div class="number">
                                <h5 class="title">標的即期費率</h5>
                                <p :class="ele.targetLoaded ? '' : 'skeleton-loading'">{{ ele.params.marketLastRate | rate(4) }}%</p>
                            </div>
                            <div class="number">
                                <h5 class="title">標的預期費率</h5>
                                <p :class="ele.targetLoaded ? '' : 'skeleton-loading'">{{ ele.params.marketNextRate | rate(4) }}%</p>
                            </div>
                            <div class="number">
                                <h5 class="title">資金費率報酬</h5>
                                <p>{{ele.cum_return | rate(4, false)}} <span>USD</span></p>
                            </div>
                            <div class="number">
                                <h5 class="title">建議平倉</h5>
                                <p>{{ ele.suggest_out ? '是' : '否' }}</p>
                            </div>
                            <div class="number actions">
                                <button :class="`outnow ${(ele.wanna_out || !ele.hold_position) ? 'disabled' : ''}`" @click="!(ele.wanna_out || !ele.hold_position) && robotClosePosition(ele._id)">立即平倉</button>
                                <button v-if="ele.hold_position" class="alreadyout"  @click="robotCloseManually(ele._id)">已自行平倉</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="ele.type == 'lending'" class="robot lending" :key="ind" :data-id="ele._id">
                    <div class="infos">
                        <div class="left">
                            <img :src="loadIcon(ele.params.market.substring(1) == 'UST' ? 'USDT' : ele.params.market.substring(1))" alt="" class="icon">
                            <h3 class="name"><h5>放貸機器人</h5>{{ ele.name }}</h3>
                            <span :class="`status${ ele.status == 0 ? '' : ' pause' }`">
                                {{ ele.status == 0 ? '運行中' : '已暫停' }}
                                <div v-if="ele.operate_logs && ele.operate_logs != ''" :data-tip="ele.operate_logs" :style="`background-image: url(${ ele.status == 0 ? require('../../assets/images/info.svg') : require('../../assets/images/info-white.svg')});`"></div>
                            </span>
                        </div>
                        <div class="right">
                            <span>放貸標的</span>
                            <h3>{{ ele.params.market.substring(1) == 'UST' ? 'USDT' : ele.params.market.substring(1) }}</h3>
                            <div class="controller">
                                <img src="../../assets/images/delete.svg" alt="" @click="deleteRobot(ele._id)" class="delete" title="刪除機器人">
                                <img src="../../assets/images/history.svg" @click="openModal(ele._id)" alt="" class="history" title="交易紀錄">
                                <img :src="ele.status == 0 ? require('../../assets/images/pause.svg') : require('../../assets/images/play.svg')" @click="robotStatus(ele._id, Math.abs(ele.status - 1))" alt="" class="pause" title="暫停機器人">
                                <img src="../../assets/images/edit.svg" @click="robotType=0;$refs.robotSettingModal.openModal(1, 0, robotList.filter(e => e._id == ele._id)[0]);" alt="" class="edit" title="編輯機器人">
                            </div>
                        </div>
                    </div>
                    <div class="status">
                        <div class="chart">
                            <h5 class="title">
                                <font>資金借貸狀況</font>
                                <span v-if="ele.balanceLoaded">{{ ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? acc+cur.spot_size : acc , 0) : 0 | rate(4, false) }} / {{ (ele.balance + (ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? acc+cur.spot_size : acc , 0) : 0)) | rate(4, false) }} ({{ ele.params.market.substring(1) == 'UST' ? 'USDT' : ele.params.market.substring(1) }})</span>
                                <div data-tip="本金額為目前可用金額，實際金額請參照Bitfinex"></div>
                            </h5>
                            <div :class="`progressbar ${!ele.balanceLoaded && 'skeleton-loading'}`">
                                <div class="progress" :style="`width: ${((ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? acc+cur.spot_size : acc , 0) : 0) / (ele.balance + (ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? acc+cur.spot_size : acc , 0) : 0)) * 100).toFixed(1)}%;`">
                                    <span class="perc">{{((ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? acc+cur.spot_size : acc , 0) : 0) / (ele.balance + (ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? acc+cur.spot_size : acc , 0) : 0)) * 100).toFixed(1)}}%</span>
                                </div>
                            </div>
                            <div class="statics">
                                <h5 class="number title">平均利率
                                    <p>{{ calcAvgProfit(ele.records) | rate(2) }}<span>%</span><span v-if="ele.frrLoaded">(參考FRR： {{ ele.frr | rate(2) }}%)</span></p>
                                    <!--
                                    <p>{{ ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? (acc + (cur.spot_price * cur.spot_size)) : acc , 0) / ele.records.reduce((acc, cur) => cur.position_type == 'open' ? (acc + cur.spot_size) : acc , 0) * 360 : 0 | rate(2) }}<span>%</span><span v-if="ele.frrLoaded">(參考FRR： {{ ele.frr | rate(2) }}%)</span></p>
                                    -->
                                </h5>
                                <h5 class="number title">累積獲利
                                    <p>{{ ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? (acc + (cur.spot_price * cur.spot_size / 24 * Math.ceil((new Date().getTime()-new Date(cur.time).getTime()) / 1000 / 60 / 60))) : (acc + (cur.spot_price * cur.spot_size / 24 * Math.ceil((new Date(cur.payout_time).getTime()-new Date(cur.time).getTime()) / 1000 / 60 / 60))) , 0) : 0 | rate(4, false) }}<span>{{ ele.params.market.substring(1) == 'UST' ? 'USDT' : ele.params.market.substring(1) }}</span></p>
                                </h5>
                            </div>
                        </div>
                        <div class="numbers">
                            <div class="number mobile">
                                <h5 class="title">平均利率</h5>
                                <p>{{ calcAvgProfit(ele.records) | rate(2) }}<span>%</span><span v-if="ele.frrLoaded">(參考FRR： {{ ele.frr | rate(2) }}%)</span></p>
                            </div>
                            <div class="number mobile">
                                <h5 class="title">累積獲利</h5>
                                <p>{{ ele.records.length ? ele.records.reduce((acc, cur) => cur.position_type == 'open' ? (acc + (cur.spot_price * cur.spot_size / 24 * Math.ceil((new Date().getTime()-new Date(cur.time).getTime()) / 1000 / 60 / 60))) : (acc + (cur.spot_price * cur.spot_size / 24 * Math.ceil((new Date(cur.payout_time).getTime()-new Date(cur.time).getTime()) / 1000 / 60 / 60))) , 0) : 0 | rate(4, false) }}<span>{{ ele.params.market.substring(1) == 'UST' ? 'USDT' : ele.params.market.substring(1) }}</span></p>
                            </div>
                            <div class="number">
                                <h5 class="title">目標年化利率</h5>
                                <p>{{ ele.params.min_rate | rate(2, false) }}%</p>
                            </div>
                            <div class="number">
                                <h5 class="title">最低保留金額</h5>
                                <p>{{ ele.params.min_remain | rate(2, false) }} <span>{{ele.params.market.substring(1) == 'UST' ? 'USDT' : ele.params.market.substring(1)}}</span></p>
                            </div>
                            <div class="number">
                                <h5 class="title">借貸天數</h5>
                                <p>{{ele.params.period}} <span>天</span></p>
                            </div>
                            <div class="number">
                                <h5 class="title">AI Level</h5>
                                <p>Auto <span>({{ele.params.ai_level}})</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="robot-last"></div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import RobotsListsChart from '@/components/RobotsListsChart';
import RobotSettingModal from '@/components/RobotSettingModal';
import Cookies from 'js-cookie'
import {apiFundingRate, apiTargetRate, apiLending, apiLendingFrr} from '@/apis/market.js';
import {apiGetBot, apiEditBot, apiLendingBalance, apiClosePosition, apiCloseManually} from '@/apis/robot.js';
// import {apiNewsDetail} from '@/apis/news.js';

export default {
    name: 'RobotsList',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
            robotType: 0, //0:lending , 1:funding rate
            currencyList: [],
            lendingCurrencyList: [],
            robotList: [],
            recordModalData: [],
            recordModalType: 'fr_arb',
            loaded: false
        }
    },
    components: {
        RobotsListsChart,
        RobotSettingModal,
    },
    computed: {
        myStyles() {
          return {
            height: '100%',
            position: 'relative',
          };
        },
        filterRobotList() {
            return this.robotList.filter(ele => ele.status != 2)
        },
    },
    methods: {
        openModal(id=""){
            this.recordModalData = this.robotList.filter(ele => ele._id == id)[0].records
            this.recordModalType = this.robotList.filter(ele => ele._id == id)[0].type
            $('.db-modal.robots-history').fadeIn(300).css('display', 'flex');
        },
        closeModal(){
            $('.db-modal.robots-history').fadeOut(300);
        },
        loadCurrency(){
            apiFundingRate().then(res => {
                if(res.data.status){
                    this.currencyList = res.data.data.map(ele => {
                        return {
                            title: ele.future_name,
                            value: ele.future_name
                        };
                    })
                }
            })
        },
        loadLendingCurrency(){
            apiLending().then(res => {
                if(res.data.status){
                    this.lendingCurrencyList = res.data.data.map(ele => {
                        return {
                            title: ele.currency,
                            value: ele.currency
                        };
                    })
                }
            })
        },
        loadRobots(){
            apiGetBot().then(res => {
                if(res.data.status){
                    let data = res.data.data;
                    data.filter(ele => ele.status != 2 && ele.type == 'fr_arb').forEach(ele => {
                        ele.targetLoaded = false
                        apiTargetRate(ele.params.market).then(res => {
                            ele.marketHistory = res.data.data.history.slice(-24).filter((ele, ind) => ind % 2 == 1).map(ele => (ele*100).toFixed(4))    
                            ele.marketHistoryLast = res.data.data.update_time
                            ele.params.marketLastRate = res.data.data.last_rate
                            ele.params.marketNextRate = res.data.data.next_rate
                            ele.targetLoaded = true
                            this.$forceUpdate();
                        })
                    })
                    data.filter(ele => ele.status != 2 && ele.type == 'lending').forEach(ele => {
                        ele.balanceLoaded = false
                        apiLendingBalance(ele.params.market).then(res => {
                            ele.balance = res.data.status ? res.data.balance : 0
                            ele.balanceLoaded = true
                            console.log(ele.balance)
                            this.$forceUpdate();
                        })
                    })
                    data.filter(ele => ele.status != 2 && ele.type == 'lending').forEach(ele => {
                        ele.frrLoaded = false
                        apiLendingFrr(ele.params.market).then(res => {
                            if(res.data.status){
                                ele.frrLoaded = true
                                ele.frr = res.data.data.frr
                            }
                        })
                    })
                    this.robotList = data;
                    this.loaded = true;
                }else{
                    if(res.data.msg == 'Not login'){
                        this.$toast.error("您已被登出！請重新登入", {
                            timeout: 2000
                        });
                        Cookies.remove('dcbotLogin');
                        Cookies.remove('dcbotLogin_uname');
                        window.location = '/';
                    }
                }
            })
        },
        deleteRobot(id=""){
            this.$confirm('確定刪除此機器人？', `機器人『${this.robotList.filter(ele => ele._id == id)[0].name}』將會被刪除。`).then(res => {
                if(res){
                    apiEditBot({
                        b_id: id,
                        data: {
                            status: 2
                        }
                    }).then(res => {
                        if(res.data.status){
                            this.$toast.success('刪除成功！', {
                                timeout: 2000
                            });
                            window.location = "/dashboard/robots"
                            $('.db-modal.robots-setting').fadeOut(300);
                        }else{
                            if(res.data.msg == 'Not login'){
                                this.$toast.error("您已被登出！請重新登入", {
                                    timeout: 2000
                                });
                                Cookies.remove('dcbotLogin');
                                Cookies.remove('dcbotLogin_uname');
                                window.location = '/';
                            }else{
                                this.$toast.error(res.data.msg, {
                                    timeout: 2000
                                });
                            }
                        }
                    })
                }
            })
        },
        loadIcon(name = ""){
            if(name == "GBP")return `https://static.bitfinex.com/images/icons/GBP-alt.svg`;
            try {
                return require(`@/assets/images/crypto-logos/${name.replace('-PERP', '')}.png`);
            } catch (e) {
                // return require(`@/assets/images/crypto-default.png`);
                return `https://static.bitfinex.com/images/icons/${name.replace('-PERP', '')}-alt.svg`;
            }
        },
        robotStatus(id = "", status = 1){
            if(status == 1){
                this.$confirm('確定暫停此機器人？', `機器人『${this.robotList.filter(ele => ele._id == id)[0].name}』將會被暫停。`).then(res => {
                    if(res){
                        apiEditBot({
                            b_id: id,
                            data: {
                                status: status
                            }
                        }).then(res => {
                            if(res.data.status){
                                this.$toast.success('更新成功！', {
                                    timeout: 2000
                                });
                                window.location = "/dashboard/robots"
                                $('.db-modal.robots-setting').fadeOut(300);
                            }else{
                                if(res.data.msg == 'Not login'){
                                    this.$toast.error("您已被登出！請重新登入", {
                                        timeout: 2000
                                    });
                                    Cookies.remove('dcbotLogin');
                                    Cookies.remove('dcbotLogin_uname');
                                    window.location = '/';
                                }else{
                                    this.$toast.error(res.data.msg, {
                                        timeout: 2000
                                    });
                                }
                            }
                        })
                    }
                })
            }else{
                apiEditBot({
                    b_id: id,
                    data: {
                        status: status
                    }
                }).then(res => {
                    if(res.data.status){
                        this.$toast.success('更新成功！', {
                            timeout: 2000
                        });
                        window.location = "/dashboard/robots"
                        $('.db-modal.robots-setting').fadeOut(300);
                    }else{
                        if(res.data.msg == 'Not login'){
                            this.$toast.error("您已被登出！請重新登入", {
                                timeout: 2000
                            });
                            Cookies.remove('dcbotLogin');
                            Cookies.remove('dcbotLogin_uname');
                            window.location = '/';
                        }else{
                            this.$toast.error(res.data.msg, {
                                timeout: 2000
                            });
                        }
                    }
                })
            }
        },
        robotClosePosition(id){
            this.$confirm('確定您要立即平倉此機器人？', `機器人『${this.robotList.filter(ele => ele._id == id)[0].name}』將會立即執行平倉動作。`).then(res => {
                if(res){
                    apiClosePosition({
                        b_id: id
                    }).then(res => {
                        if(res.data.status){
                            this.$toast.success('更新成功！', {
                                timeout: 2000
                            });
                            window.location = "/dashboard/robots"
                            $('.db-modal.robots-setting').fadeOut(300);
                        }else{
                            if(res.data.msg == 'Not login'){
                                this.$toast.error("您已被登出！請重新登入", {
                                    timeout: 2000
                                });
                                Cookies.remove('dcbotLogin');
                                Cookies.remove('dcbotLogin_uname');
                                window.location = '/';
                            }else{
                                this.$toast.error(res.data.msg, {
                                    timeout: 2000
                                });
                            }
                        }
                    })
                }
            });
        },
        robotCloseManually(id){
            this.$confirm('確定您已自行平倉此機器人？', `機器人『${this.robotList.filter(ele => ele._id == id)[0].name}』將會繼續偵測進場點位。`).then(res => {
                if(res){
                    apiCloseManually({
                        b_id: id
                    }).then(res => {
                        if(res.data.status){
                            this.$toast.success('更新成功！', {
                                timeout: 2000
                            });
                            window.location = "/dashboard/robots"
                            $('.db-modal.robots-setting').fadeOut(300);
                        }else{
                            if(res.data.msg == 'Not login'){
                                this.$toast.error("您已被登出！請重新登入", {
                                    timeout: 2000
                                });
                                Cookies.remove('dcbotLogin');
                                Cookies.remove('dcbotLogin_uname');
                                window.location = '/';
                            }else{
                                this.$toast.error(res.data.msg, {
                                    timeout: 2000
                                });
                            }
                        }
                    })
                }
            })
        },
        calcAvgProfit(rec){
            if(rec.length){
                let p = rec.reduce((acc, cur) => cur.position_type == 'open' ? (acc + (cur.spot_price * cur.spot_size)) : acc , 0)
                let s = rec.reduce((acc, cur) => cur.position_type == 'open' ? (acc + cur.spot_size) : acc , 0)
                if(s == 0)return 0;
                if(p == 0)return 0;
                return p/s*360;
            }
            return 0
        }
    },
    mounted() {
        this.loadCurrency();
        this.loadLendingCurrency();
        this.loadRobots();
        $('body').on('click', '.db-modal:not(.confirm-dialog):not(.robots-setting)', function(){
            $(this).fadeOut(300);
        })
        $('body').on('click', '.db-modal > .content', function(e){
            e.stopPropagation();
        })
    },
}
</script>
