<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'

export default {
    extends: Line,
    data() {
        return{
            options: {
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            maxTicksLimit: 3
                        },
                        gridLines: {
                            drawOnChartArea: false
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            maxTicksLimit: 7
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }]
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            let label = context.dataset.label || '';
                            return label + '%';
                        }
                    }
                }
            },
            chartData: {
                labels: this.dateArray(),
                datasets: [{
                    label: '即期費率',
                    data: this.records,
                    fill: false,
                    lineTension: 0,
                    borderColor: '#84DCCC',
                    borderWidth: 2,
                    pointBackgroundColor: '#84DCCC',
                    pointBorderColor: '#84DCCC',
                }]
            },
        }
    },
    props: {
        chartTarget: {
            type: String,
            default: ""
        },
        records: {
            type: Array,
            default: () => []
        },
        last: {
            type: String,
            default: "2022-02-22T10:00:00.000Z"
        }
    },
    methods: {
        dateArray(){ // 0 - 11  0 2 4 6 8 22
            return Array.from({length: 12}, (_, i) => {
                return moment(this.last).clone().subtract(22 - (i*2), 'hours').format('MM/DD HH:mm')
            })
        }
    },
    mounted () {
        this.renderChart(this.chartData, this.options)
    }
}
</script>
